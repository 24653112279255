import { Avatar } from 'antd'
import clsx from 'clsx'
import styles from './UserAvatar.module.scss'
import React from 'react'
import { AvatarSize } from 'antd/es/avatar/AvatarContext'

export enum Size {
  DEFAULT = 'default',
  SMALL = 'small',
  LARGE = 'large',
}

export type UserAvatarProps = {
  label?: string
  size?: AvatarSize
  image?: string
  color?: string
  delegationStatus?: string
  avatarStyles?: {}
}

export const getInitials = (label: string): string | null => {
  const initials = label
    .split(' ')
    .map((part) => part.charAt(0))
    .slice(0, 3)
    .join('')
    .toUpperCase()
  return initials
}

const UserAvatar = ({
  label = '',
  size = 'small',
  image = '',
  color = '',
  delegationStatus = '',
  avatarStyles,
}: UserAvatarProps) => {
  const iconClassName = clsx(
    delegationStatus && styles[delegationStatus.toLowerCase() + 'Status'],
  )
  const avatarStyle = color
    ? {
        backgroundColor: color,
        color: 'var(--background-color)',
        ...avatarStyles,
      }
    : {
        backgroundColor: `var(${image ? '--user-avatar-image-background' : '--user-avatar-background'})`,
        ...avatarStyles,
      }

  if (image) {
    return (
      <Avatar
        size={size}
        style={avatarStyle}
        src={image}
        className={styles.avatarImage}
      />
    )
  }

  return (
    <Avatar size={size} style={avatarStyle} className={iconClassName}>
      {getInitials(label)}
    </Avatar>
  )
}

export default UserAvatar
