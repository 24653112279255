import { StateCreator } from 'zustand'
import { ApiState } from './Auth.slice'
import apiBase from 'utils/axiosBase'
import { Contact } from 'types/Contacts'
import { TaskGroup } from 'types/Tasks'
import { getTimeZone } from 'utils/IntlUtlis'
import { AxiosResponse } from 'axios'
import { decamelizeKeys } from 'humps'

export type Invite = {
  email: string
  isAdmin: boolean
  role: number
}

export type CollaboratorInvite = {
  email: string
  task: string
}

type InviteeTask = {
  id: string
}

export type Invitee = {
  email: string
  status: string
  task: InviteeTask
  name?: string
  image?: string
}

export type ConfirmInvite = {
  key: string
  password?: string
  userType?: number
}

export type DelegationInvite = {
  action: string
  email: string
  userType: string
}

export type GroupInvite = {
  email: string
  userType: string
  groupDescription: string
  groupMembersCount: number
  groupOwner: string
  groupOwnerEmail: string
  groupTitle: string
}

export type Confirmation = {
  key: string
  firstName?: string
  lastName?: string
  password?: string
  comment?: string
  userType?: number
}

export interface DelegationState extends ApiState {
  contacts: Contact[]
  allGroups: TaskGroup[]
  pendingInvites: Invitee[]
  checkGroupInvite: (key: string) => Promise<GroupInvite>
  confirmGroupInvite: (
    data: ConfirmInvite,
  ) => Promise<{ detail: string; email: string }>
  inviteCollaborator: (data: CollaboratorInvite) => Promise<void>
  postInvitation: (
    email: string,
    invitedBy: string,
    taskId: string,
    action: string,
  ) => Promise<AxiosResponse>
  addCollaboratorInvite: (
    email: string,
    task: InviteeTask,
    name: string,
    image?: string,
  ) => void
  resetCollaboratorInvites: () => void
  deleteCollaboratorInvite: (email: string) => Promise<{ detail: string }>
  deleteDelegationInvite: (email: string) => Promise<{ detail: string }>
  getAllContacts: () => Promise<Contact[]>
  getAllGroups: () => Promise<TaskGroup[]>
  checkDelegationInvite: (key: string) => Promise<DelegationInvite>
  confirmDelegationInvite: (
    data: Confirmation,
  ) => Promise<{ detail: string; email: string }>
  expirePendingInvites: () => Promise<{ detail: string }>
  checkAttendeeInvite: (key: string) => Promise<Partial<DelegationInvite>>
  confirmAttendeeInvite: (data: Confirmation) => Promise<{ detail: string }>
}

const removePaginationQuery = '?page=1&offset=0&limit=20'

export const createDelegationSlice: StateCreator<DelegationState> = (
  set,
  get,
) => ({
  contacts: [],
  allGroups: [],
  pendingInvites: [],
  confirmGroupInvite: async (data: ConfirmInvite) => {
    const response = await apiBase.post(
      `/${process.env.REACT_APP_API_VERSION}/group/member/invitation/request`,
      decamelizeKeys(data),
    )

    return response?.data
  },
  checkGroupInvite: async (key: string) => {
    const response = await apiBase.get(
      `/${process.env.REACT_APP_API_VERSION}/group/member/invitation/request?key=${key}`,
    )
    return response.data
  },
  inviteCollaborator: async (data: CollaboratorInvite) => {
    const response = await get().api!(
      'POST',
      '/task/delegation/invitation',
      data,
    )

    return response?.data
  },
  postInvitation: async (
    email: string,
    invitedBy: string,
    taskId: string,
    action: string,
  ) => {
    const response = await apiBase.post(
      `/${process.env.REACT_APP_API_VERSION}/task/delegation/invitation/request`,
      {
        email,
        invited_by: invitedBy,
        task_id: taskId,
        action,
        user_timezone: getTimeZone().value,
      },
    )
    // @ts-ignore
    get().setShouldUpdateTasks(true)

    return response?.data
  },
  getCollaboratorInvites: async () => {
    const response = await get().api!(
      'GET',
      `/task/delegation/invitation${removePaginationQuery}`,
    )

    return response?.data
  },
  addCollaboratorInvite: (
    email: string,
    task: InviteeTask,
    name: string,
    image = undefined,
  ) => {
    set(() => {
      return {
        pendingInvites: [
          {
            email: email,
            status: 'pending',
            task: task,
            name: name,
            image: image,
          },
        ],
      }
    })
  },
  deleteCollaboratorInvite: async (email: string) => {
    const response = await get().api!(
      'DELETE',
      `task/delegation/invitation?email=${email}`,
    )

    set((state) => {
      const newInvites = state.pendingInvites.filter((invite) => {
        return invite.email !== email
      })

      return { pendingInvites: newInvites }
    })

    return response?.data
  },
  resetCollaboratorInvites: () => {
    set(() => {
      return {
        pendingInvites: [],
      }
    })
  },
  deleteDelegationInvite: async (id: string) => {
    const response = await get().api!('DELETE', `task/delegation/${id}`)

    return response?.data
  },
  getAllContacts: async () => {
    const response = await get().api!('GET', `/contact`)
    const result: Contact[] = response?.data
    set(() => {
      return {
        contacts: result,
      }
    })

    return response?.data
  },
  getAllGroups: async () => {
    const response = await get().api!(
      'GET',
      `/group/member${removePaginationQuery}`,
    )
    const result: TaskGroup[] = response?.data.results
    set(() => {
      return {
        allGroups: result,
      }
    })
    return result
  },
  checkDelegationInvite: async (key: string) => {
    const response = await apiBase.get(
      `/${process.env.REACT_APP_API_VERSION}/task/delegation/invitation/request?key=${key}`,
    )

    return response?.data
  },
  confirmDelegationInvite: async (data: Confirmation) => {
    const response = await apiBase.post(
      `/${process.env.REACT_APP_API_VERSION}/task/delegation/invitation/request`,
      { ...decamelizeKeys(data), user_timezone: getTimeZone().value },
    )

    return response?.data
  },
  expirePendingInvites: async () => {
    const response = await get().api!('GET', '/task/validation')

    return response?.data
  },
  checkAttendeeInvite: async (key: string) => {
    const response = await apiBase.get(
      `/${process.env.REACT_APP_API_VERSION}/task/schedule/invitation/request?key=${key}`,
    )

    return response?.data
  },
  confirmAttendeeInvite: async (data: Confirmation) => {
    const response = await apiBase.post(
      `/${process.env.REACT_APP_API_VERSION}/task/schedule/invitation/request`,
      data,
    )

    return response?.data
  },
})
