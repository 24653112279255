import {
  CloseCircleOutlined,
  CloseOutlined,
  PlusOutlined,
  RedoOutlined,
} from '@ant-design/icons'
import Switch from '@mui/material/Switch'
import {
  App,
  Button,
  Col,
  Empty,
  Input,
  Layout,
  List,
  Menu,
  MenuProps,
  Row,
  Space,
  Typography,
  Form,
} from 'antd'
import type { InputRef } from 'antd'
import { SearchProps } from 'antd/es/input'
import { motion } from 'framer-motion'
import Fuse from 'fuse.js'
import useStore from 'hooks/useStore'
import { nanoid } from 'nanoid'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem, MenuItemChild, PendingAssignee } from 'types/Tasks'
import { Userpilot } from 'userpilot'
import styles from './DelegationDrawer.module.scss'
import SelectTag from './SelectTag'
import { useTaskEditor } from '../useTaskEditor'

type DelegationDrawerProps = {
  isOpen: Boolean
  onCancel: () => void
  onInvite: () => void
  delegate: (value: MenuItemChild) => void
  pendingAssignees: PendingAssignee[]
  resetPendingAssignees: () => void
  taskId: string
  assigneeHasEditorPermissions: boolean
  setAssigneeHasEditorPermissions: Dispatch<SetStateAction<boolean>>
}

const { Text, Title } = Typography
const { Search } = Input
const { Header, Footer } = Layout

const options = {
  shouldSort: true,
  threshold: 0.5,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
}

const searchContacts = (
  query: string,
  list: MenuItemChild[],
  keys: string[],
) => {
  const fuse = new Fuse(list, { ...options, keys })
  return fuse.search(query)
}

const DelegationDrawer = ({
  isOpen,
  onCancel,
  onInvite,
  delegate,
  pendingAssignees,
  resetPendingAssignees,
  taskId,
  assigneeHasEditorPermissions,
  setAssigneeHasEditorPermissions,
}: DelegationDrawerProps) => {
  const { t } = useTranslation()
  const app = App.useApp()
  const { message } = App.useApp()
  const [form] = Form.useForm()

  const { pendingAssigneesList, setPendingAssigneesList } = useTaskEditor()

  const user = useStore((state) => state.user?.data)
  const allGroups = useStore((state) => state.allGroups)
  const contacts = useStore((state) => state.contacts)
  const inviteCollaborator = useStore((state) => state.inviteCollaborator)
  const deleteCollaboratorInvite = useStore(
    (state) => state.deleteCollaboratorInvite,
  )
  const resetCollaboratorInvites = useStore(
    (state) => state.resetCollaboratorInvites,
  )
  const thumbnailAvatar = useStore((state) => state.thumbnailAvatar)

  const inputRef = useRef<InputRef>(null)

  const [groupMenuItems, setGroupMenuItems] = useState<MenuItem[] | []>([])
  const [contactMenuItems, setContactMenuItems] = useState<MenuItem | null>()
  const [inviteMenuItems, setIviteMenuItems] = useState<MenuItem | null>()
  const [groupList, setGroupList] = useState<MenuItemChild[]>([])
  const [inviteList, setInviteList] = useState<MenuItemChild[]>([])
  const [contactList, setContactList] = useState<MenuItemChild[]>([])
  const [searchList, setSearchList] = useState<MenuItemChild[]>([])
  const [selectedKey, setSelectedKey] = useState<string[]>(['pending'])
  const [selectedChild, setSelectedChild] = useState<MenuItemChild | null>()
  const [isSearching, setIsSearching] = useState(false)

  const fullName = `${user?.firstName} ${user?.lastName}`.trim()
  const userEmail = user?.email
  const userId = user?.id

  const deleteInvite = (email: string) => {
    deleteCollaboratorInvite(email)
      .then((data) => {
        app.notification.success({
          message: data.detail,
        })
        resetCollaboratorInvites()
        resetPendingAssignees()
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          resetCollaboratorInvites()
          resetPendingAssignees()
        } else {
          app.notification.error({
            message: error?.response?.data?.detail || error.message,
          })
        }
      })
  }

  const createInvite = (email: string, taskId: string) => {
    inviteCollaborator({ email: email, task: taskId })
      .then(() => {
        message.success(t('invite.success', { ns: 'common' }))
        setPendingAssigneesList([{ email: email, status: 'Pending' }])
      })
      .catch((error) => {
        message.error(
          error.response.data.fallback_message ||
            t('try-again', { ns: 'validation' }),
        )
      })
  }

  useEffect(() => {
    const groupMenuItem = allGroups.map((item) => {
      const { groupMembers = [], id } = item

      const childList = groupMembers
        .map((child) => {
          const {
            name = '',
            email = '',
            status = '',
            user = '',
            avatar = '',
          } = child

          return {
            label: (
              <SelectTag label={name} secondaryLabel={email} image={avatar} />
            ),
            key: `${user}-${nanoid(5)}`,
            email: email,
            disabled: false,
            status: status,
            user: user,
            group: id.toString(),
            name: name.trim(),
            image: avatar,
          }
        })
        .filter((item) => {
          return item?.email?.trim() !== userEmail && item?.email?.trim()
        })

      if (childList.length > 0) {
        setGroupList((prev) => [...prev, ...childList])
        return {
          id: item.id.toString(),
          label: item.title,
          key: `${item.title.toLowerCase()}-${nanoid(5)}`,
          children: childList,
        }
      }
      setGroupList([])
      return {}
    })

    const filterEmptyGroups = groupMenuItem.filter(
      (groupMenuItem) => Object.keys(groupMenuItem).length > 0,
    )

    if (filterEmptyGroups && filterEmptyGroups.length > 0) {
      setGroupMenuItems(filterEmptyGroups as MenuItem[])
      setSelectedKey([filterEmptyGroups[0]?.key || ''])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGroups])

  useEffect(() => {
    const newContactsList = contacts
      .map((contact) => {
        const { contactEmail = [], firstName = '', lastName = '', id } = contact
        const fullName = `${firstName || ''} ${lastName || ''}`

        return {
          label: (
            <SelectTag
              label={fullName}
              secondaryLabel={contactEmail?.[0]?.email}
            />
          ),
          key: `${id}-${nanoid(5)}`,
          email: contactEmail?.[0]?.email,
          disabled: false,
          status: '',
          user: '',
          group: '',
          name: fullName.trim(),
        }
      })
      .filter((item) => {
        return item.email !== userEmail && item.email
      })
    if (newContactsList.length > 0) {
      setContactList(newContactsList)
      const contactsMenuItem = {
        id: nanoid(5),
        label: 'Contacts',
        key: 'contacts',
        children: newContactsList,
      }

      setContactMenuItems(contactsMenuItem)
    } else {
      setContactList([])
      setContactMenuItems(null)
    }
  }, [contacts, delegate, userEmail])

  useEffect(() => {
    if (pendingAssignees && pendingAssignees.length > 0) {
      const pendingInvitesList = pendingAssignees
        .map((invite) => {
          const { email = '', status = '' } = invite

          return {
            label: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingRight: 8,
                }}
              >
                <SelectTag label={''} secondaryLabel={email} />
                <Space>
                  {status !== 'Expired' ? (
                    <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      icon={<RedoOutlined />}
                      style={{
                        backgroundColor: 'var(--completed-color)',
                      }}
                      onClick={() => createInvite(email, taskId)}
                    />
                  ) : null}
                  <Button
                    type="primary"
                    shape="circle"
                    size="small"
                    danger
                    icon={<CloseOutlined />}
                    onClick={() => {
                      setPendingAssigneesList([])
                      deleteInvite(email)
                    }}
                  />
                </Space>
              </div>
            ),
            key: nanoid(5).toString(),
            email: email,
            disabled: false,
            status: status,
            user: '',
            group: '',
            name: '',
          }
        })
        .filter((item) => {
          return item.email !== user?.email && item.status === 'Pending'
        })

      if (pendingInvitesList.length > 0) {
        setInviteList(pendingInvitesList)
      }

      const pendingInvitesMenuItem = {
        id: nanoid(5),
        label: 'Pending',
        key: 'pending',
        children: pendingInvitesList,
      }

      setIviteMenuItems(pendingInvitesMenuItem)
    } else {
      setInviteList([])
      setIviteMenuItems(null)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingAssignees, taskId])

  useEffect(() => {
    if (pendingAssigneesList && pendingAssigneesList.length > 0) {
      const pendingAssignee = [
        {
          value: pendingAssigneesList?.[0]?.email?.trim(),
          label: (
            <SelectTag
              label={''}
              secondaryLabel={pendingAssigneesList?.[0]?.email?.trim()}
            />
          ),
          key: nanoid(5),
          email: pendingAssigneesList?.[0]?.email?.trim(),
          disabled: false,
          user: '',
          group: '',
          name: '',
        },
      ]

      setInviteList(pendingAssignee)
      setSelectedKey((prev) => [...prev, 'pending'])
    }
  }, [pendingAssigneesList])

  let menuItems: MenuItem[] = []

  if (groupMenuItems && groupMenuItems.length > 0) {
    menuItems = [...groupMenuItems]
  }

  if (contactMenuItems) {
    menuItems = [...menuItems, contactMenuItems]
  }

  if (
    pendingAssigneesList &&
    pendingAssigneesList.length > 0 &&
    inviteMenuItems
  ) {
    menuItems = [...menuItems, inviteMenuItems]
  }

  const onClick: MenuProps['onClick'] = (event) => {
    const selectedMenuItem = menuItems?.find((item) => {
      return item.key === event?.keyPath?.[1].toString()
    })
    const selectedSubItem = selectedMenuItem?.children?.find((item) => {
      return item.key === event.key
    })
    setSelectedChild(selectedSubItem)
  }

  const onSearch: SearchProps['onSearch'] = (value, _, info) => {
    if (info?.source === 'input') {
      const usersList: MenuItemChild[] = [
        ...groupList,
        ...inviteList,
        ...contactList,
      ]
      const result = searchContacts(value, usersList, ['email', 'name'])

      const newResultList = result.map((result) => {
        return result?.item
      })

      const uniqueResultList = newResultList.filter((item, index) => {
        return (
          index === newResultList.findIndex((obj) => item?.email === obj?.email)
        )
      })

      setIsSearching(true)
      if (uniqueResultList.length === 0) {
        setSearchList([{ email: 'no results' }])
      } else {
        setSearchList(uniqueResultList)
      }
    } else {
      setIsSearching(false)
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: isOpen ? 1 : 0 }}
      transition={{ duration: 0.5 }}
      className={styles.drawer}
    >
      <Space
        wrap
        direction="vertical"
        size="middle"
        style={{ width: 'calc(100% - 16px)' }}
      >
        <Header>
          <Row>
            <Title level={5}>{t('delegation-drawer.task-owner')}</Title>
          </Row>
          <SelectTag
            label={fullName}
            secondaryLabel={userEmail}
            border
            onClick={() => {
              delegate({
                user: userId,
                email: userEmail,
                label: fullName,
                image: thumbnailAvatar,
              })
              setPendingAssigneesList([])
            }}
            image={thumbnailAvatar}
          />
          <Form form={form}>
            <Form.Item name="search">
              <Search
                ref={inputRef}
                placeholder="Search"
                style={{ width: '100%', marginTop: 8 }}
                onSearch={onSearch}
                enterButton
                allowClear
              />
            </Form.Item>
          </Form>
        </Header>
        {isSearching && searchList.length !== 0 ? (
          <div style={{ maxHeight: 506, overflow: 'auto', paddingBottom: 32 }}>
            <List
              dataSource={searchList}
              bordered={false}
              renderItem={(item) => {
                if (item?.email === 'no results') {
                  return (
                    <List.Item style={{ paddingInline: 16 }}>
                      <Text
                        ellipsis
                        style={{
                          backgroundColor: 'transparent',
                          verticalAlign: 'middle',
                          paddingTop: 6,
                          border: 'none',
                        }}
                      >
                        {t('delegation-drawer.zero-results')}
                      </Text>
                      <CloseCircleOutlined
                        onClick={() => {
                          setSearchList([])
                          setIsSearching(false)
                          form.resetFields()
                        }}
                      />
                    </List.Item>
                  )
                }
                return (
                  <List.Item
                    style={{
                      paddingInline: 0,
                      border: 'none',
                      backgroundColor: 'var(--offset-background-color)',
                      marginBottom: 4,
                    }}
                    onClick={() => {
                      delegate({
                        user: '',
                        email: item?.email,
                        label: item?.email,
                      })
                    }}
                  >
                    <SelectTag
                      label={item?.name || ''}
                      secondaryLabel={item?.email}
                      border={false}
                    />
                  </List.Item>
                )
              }}
            />
          </div>
        ) : (
          <></>
        )}
        {!isSearching ? (
          <div style={{ maxHeight: 506, overflow: 'auto', paddingBottom: 32 }}>
            {menuItems && menuItems.length > 0 ? (
              <Menu
                mode="inline"
                theme="light"
                defaultOpenKeys={selectedKey}
                items={menuItems}
                onSelect={onClick}
              />
            ) : (
              <Empty description={t('delegation-drawer.empty-text')} />
            )}
          </div>
        ) : (
          <></>
        )}
        <Footer
          style={{
            width: 'calc(100% - 16px)',
          }}
        >
          <Row>
            <Col span={24}>
              <Text>{t('delegation-drawer.grant-editor-permissions')}</Text>
              <Switch
                checked={assigneeHasEditorPermissions}
                onChange={(evt) =>
                  setAssigneeHasEditorPermissions(evt.target.checked)
                }
              />
            </Col>
          </Row>
          <div style={{ paddingBottom: 8 }}>
            <Text
              type="secondary"
              style={{ paddingBlock: 8, backgroundColor: 'transparent' }}
            >
              {t('delegation-drawer.dont-see-them')}
            </Text>
          </div>
          <Space wrap style={{ width: '100%', justifyContent: 'flex-start' }}>
            <Button
              onClick={() => {
                Userpilot.track('Clicked Invite Other Users button')
                onInvite()
              }}
              type="primary"
              style={{ backgroundColor: 'var(--completed-color)' }}
            >
              {t('delegation-drawer.invite-other-user')}
              <PlusOutlined />
            </Button>
            <Button onClick={onCancel}>
              {t('delegation-drawer.cancel-text')}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                Userpilot.track('Clicked Assign button')
                delegate(selectedChild!)
                setPendingAssigneesList([
                  {
                    email: selectedChild?.email || '',
                    status: 'Pending',
                    name: selectedChild?.name || '',
                  },
                ])
              }}
            >
              {t('delegation-drawer.assign-text')}
            </Button>
          </Space>
        </Footer>
      </Space>
    </motion.div>
  )
}

export default DelegationDrawer
