import dayjs, { Dayjs, OpUnitType } from 'dayjs'
import { generateKeyBetween } from 'fractional-indexing'
import { decode } from 'html-entities'
import {
  ListOption,
  TaskLists,
  TaskType,
  UploadedFile,
} from 'services/Tasks.slice'
import {
  CalendarScheduledTask,
  GetCalendarScheduledTaskList,
} from 'types/Calendar'
import { CurriculumStatusId, CurriculumTaskItem } from 'types/Curriculum'
import { FlexibleTime } from 'types/FlexibleTime'
import {
  DescriptionJsonNode,
  Frequency,
  FuzzyTimeOptions,
  PaginationOptions,
  ScheduledTask,
  Task,
  TaskCategory,
  TaskFormProps,
  TaskGroup,
  TaskItem,
  TaskNodeData,
  TaskPriority,
  TaskPriorityGroup,
  TaskPriorityGroupGlobal,
  TaskRecurrence,
  TaskStatus,
  TaskStatusId,
} from 'types/Tasks'
import { decodeExDate, decodeRDate } from 'views/Calendar/CalendarUtils'
import { PermissionRoleIDs } from './permissions'
import { recurRuleFromString } from './recurRule'

const AssigneeInvitationStatus = Object.freeze({
  ACCEPTED: 'Accepted',
  SENT: 'Sent',
})

export const categoryColors = {
  personal: 'var(--personal-category-color)',
  family: 'var(--family-category-color)',
  business: 'var(--business-category-color)',
  professional: 'var(--professional-category-color)',
  theocratic: 'var(--theocratic-category-color)',
  default: 'var(--default-category-color)',
}

export const getCategoryColorByTitle = (title: string) => {
  const sanitizedTitle = title.toLowerCase()

  return (
    categoryColors[sanitizedTitle as keyof typeof categoryColors] ||
    categoryColors.default
  )
}

export const sanitizeDestinationId = (destinationId: string) => {
  const removeStringPart = destinationId.includes('count-')
    ? destinationId.replace('count-', '')
    : destinationId
  return parseInt(removeStringPart)
}

export const getPriorityGroupById = (
  priorityGroupId: number,
  priorityGroups?: TaskPriorityGroupGlobal[],
) => {
  if (!priorityGroupId) return undefined

  return priorityGroups?.find((pg) => pg.id === priorityGroupId)
}

export const getStatusById = (
  statusId: TaskStatusId,
  statusList: TaskStatus[],
) => {
  if (!statusId) return undefined

  return statusList?.find((status) => status.id === statusId)
}

export const getGroupById = (groupId: number, groupList: TaskGroup[]) => {
  if (!groupId) return undefined

  return groupList?.find((status) => status.id === groupId)
}

export const getCategoryById = (
  categoryId: number,
  categoryList: ListOption[],
): TaskCategory | undefined => {
  if (!categoryId) return undefined

  const category = categoryList?.find((status) => status.value === categoryId)
  if (category) {
    return {
      id: category.value,
      title: category.label,
    }
  }
}

export const getFlagTitleById = (flagId: number) => {
  switch (flagId) {
    case TaskPriority.URGENT:
      return 'Urgent'

    case TaskPriority.NORMAL:
      return 'Normal'
    default:
      return ''
  }
}

export const getTaskFromTaskList = (
  taskId: string,
  taskList: { [key: string]: TaskItem[] },
) => {
  let taskToSearch: TaskItem | undefined
  for (const [, value] of Object.entries(taskList)) {
    taskToSearch = value.find((task) => task.id === taskId)
    if (taskToSearch) {
      break
    }
  }
  return taskToSearch
}

export const getPriorityGroupIdAndTaskIndex = (
  taskId: string,
  taskList: { [key: string]: TaskItem[] },
) => {
  let priorityGroupId = -1
  let taskIndex = -1
  for (const [key, value] of Object.entries(taskList)) {
    taskIndex = value.findIndex((task) => task.id === taskId)
    if (taskIndex !== -1) {
      priorityGroupId = Number(key)
      break
    }
  }
  return { periodId: priorityGroupId, taskIndex }
}

export const getTodayPriorityGroupId = (
  priorityGroups?: TaskPriorityGroupGlobal[],
) => {
  return (priorityGroups || []).find((pg) => pg.days === 0)?.id || 0
}

export const categorizeTaskForMyToday = (task: TaskItem | Task) => {
  const todayTaskPriorityGroupId = 0
  let priorityGroupId = task.priorityGroup?.id || 0
  let order = ''
  let reasonIsPinnedToToday = ''

  if (task.completionAt && dayjs().diff(dayjs(task.completionAt), 'd') > 0) {
    priorityGroupId = -1
    return { periodId: priorityGroupId, order }
  }

  if (task.status?.id === TaskStatusId.DONE) {
    priorityGroupId = todayTaskPriorityGroupId
    reasonIsPinnedToToday = 'done'
  }
  if (task.dueAt && dayjs().isSame(dayjs(task.dueAt), 'd')) {
    priorityGroupId = todayTaskPriorityGroupId
    reasonIsPinnedToToday = 'due-at'
  } else if (task.scheduledTask) {
    priorityGroupId = -1

    const rRule = recurRuleFromString(task.scheduledTask.recurRule)
    const today = dayjs().hour(0).minute(0).second(0).millisecond(0)
    const nextOccurrence =
      rRule.isRecurring() && rRule.after(today.toDate(), true)
    const isRecurringForToday =
      nextOccurrence && dayjs().diff(dayjs(nextOccurrence), 'd') === 0
    if (rRule.isRecurring()) {
      if (isRecurringForToday) {
        priorityGroupId = todayTaskPriorityGroupId
        reasonIsPinnedToToday = 'recurring'
      }
    } else if (
      task.scheduledTask?.startDate &&
      dayjs().diff(dayjs(task.scheduledTask?.startDate), 'd') === 0
    ) {
      priorityGroupId = todayTaskPriorityGroupId
      reasonIsPinnedToToday = 'scheduled'
    }
  }

  return { periodId: priorityGroupId, order, reasonIsPinnedToToday }
}

export function divideTasksByPeriod(tasks: (TaskItem | Task)[]): TaskLists {
  const taskList: TaskLists = {}
  tasks.forEach((task) => {
    const periodId = task.priorityGroup?.id || 0
    if (!taskList[periodId]) {
      taskList[periodId] = []
    }
    taskList[periodId].push(task as TaskItem)
  })
  return taskList
}

export function sortByFlagId<
  T extends { taskDetails?: { flag?: { id?: number } } },
>(list: T[]): T[] {
  return [...list].sort((a, b) => {
    const aFlagId = a.taskDetails?.flag?.id || Number.MAX_VALUE
    const bFlagId = b.taskDetails?.flag?.id || Number.MAX_VALUE

    return aFlagId - bFlagId
  })
}

export const getFriendlyRecurrence = (taskRecurrence: TaskRecurrence) => {
  if (!taskRecurrence) return ''
  const recurrenceInterval = taskRecurrence?.intervalPattern
    ? taskRecurrence?.intervalPattern.toUpperCase()
    : undefined

  const frequency =
    Frequency[recurrenceInterval as keyof typeof Frequency] || ''

  const fuzzyTime = taskRecurrence?.fuzzyTime || 0
  const fuzzyTimeOption = FuzzyTimeOptions[fuzzyTime]

  const recurrenceTitle = fuzzyTimeOption
    ? `${frequency} - ${fuzzyTimeOption}`
    : frequency

  return recurrenceTitle
}

export const buildFilterQuery = (
  filters: Map<string, string | string[] | number[]>,
) => {
  const params = new URLSearchParams()
  filters.forEach((value, key) => {
    if (value.length > 0) {
      params.append(key, Array.isArray(value) ? value.join() : value)
    }
  })
  return params.toString()
}

export const getDueDateColorIcon = (dueAt?: string): string => {
  if (!dueAt) {
    return '#000'
  }

  const dueDate = dayjs(dueAt)
  const now = dayjs()
  const daysTillDue = Math.ceil(dueDate.diff(now, 'day', true))

  if (daysTillDue > 7) {
    return '#ffba33'
  } else if (daysTillDue >= 1) {
    return '#ff8441'
  } else if (daysTillDue >= 0) {
    return '#ff4d4f'
  } else {
    return '#ef3e23'
  }
}

export const getCalendarScheduledTaskList: GetCalendarScheduledTaskList = (
  tasks: TaskItem[],
  useRecurRuleLastDate = false,
  includeFlexibleRoutines = false,
  routineTimes = undefined,
) => {
  return tasks
    .filter(
      (task) =>
        (includeFlexibleRoutines && task.scheduledTask?.flexibleTime) ||
        task.scheduledTask?.flexibleTime === FlexibleTime.SCHEDULED,
    )
    .flatMap((task: any) => getCalendarScheduledItem(task))
}

export const getCalendarScheduledItem = ({
  scheduledTask,
  title,
  level,
  user,
  group,
  id: taskId,
  status,
  routineTask,
}: TaskItem) => {
  const {
    id: scheduledTaskId,
    startDate,
    endDate,
    exDate,
    rDate,
    recurRule,
    allDay,
    futureInstances,
    initialDate,
  } = scheduledTask!

  const calendarScheduledTask = {
    startDate,
    endDate,
    allDay,
    id: scheduledTaskId.toString(),
    title,
    taskId,
    level,
    user,
    group: group?.title,
    status: status?.title,
    isRoutineInstance: !!routineTask,
  } as CalendarScheduledTask

  if (recurRule && !initialDate && !futureInstances) {
    // It's a Routine Definition but there is no future instances
    return []
  }

  if (!(recurRule && !initialDate)) {
    // It is not a Routine Definition
    return [calendarScheduledTask]
  }

  const virtualInstances = []
  const newExDates = decodeExDate(exDate || '')
  const newRDates = decodeRDate(futureInstances)
  for (const dateObj of newRDates) {
    if (!newExDates.find((exDate) => exDate.startDate === dateObj.startDate)) {
      virtualInstances.push(dateObj)
    }
  }

  return [
    ...virtualInstances.map(
      (rdateObj) =>
        ({
          ...calendarScheduledTask,
          startDate: rdateObj.startDate!,
          endDate: rdateObj.endDate!,
          id: `${scheduledTaskId.toString()};${rdateObj.startDate}`,
          rRule: 'RRULE:FREQ=DAILY;INTERVAL=1;COUNT=1',
          originalExDate: exDate,
          originalRDate: rDate,
          originalRecurRule: recurRule,
        } as CalendarScheduledTask),
    ),
  ]
}

const filterByLevelAndDelegated = (
  task: TaskItem,
  levels: number[],
  showBaseLevelTasks: boolean,
) => {
  const level = task.level?.id || 0
  if (showBaseLevelTasks) {
    return level > 8 || levels.includes(level)
  } else {
    return levels.includes(level)
  }
}

export const getFilteredTasksByLevels = (
  tasks: TaskLists,
  levels: number[],
) => {
  const showBaseLevelTasks = levels.includes(9)
  const validLevels = [...levels].filter((level) => level !== 0 && level !== 9)
  const taskList: TaskLists = {}
  for (const [key, value] of Object.entries(tasks)) {
    if (value) {
      taskList[key] = value.filter((task) =>
        filterByLevelAndDelegated(task, validLevels, showBaseLevelTasks),
      )
    }
  }
  return taskList
}

export const getFilteredPriorityGroupBySelectables = (
  priorityGroups: TaskPriorityGroupGlobal[],
) => {
  return priorityGroups.filter((pg) => pg.id < 4)
}

export const buildPaginationQuery = (pagination: PaginationOptions) => {
  const limit = pagination.limit || 20
  const offset = pagination.offset || 0
  const page = pagination.page || 1
  const paginationQuery = `page=${page}&offset=${offset}&limit=${limit}`
  return paginationQuery
}

export const getNewOrderCode = (
  tasks: TaskLists,
  destinationListId: number,
  destinationIndex: number,
  sourceListId: number,
  sourceIndex: number,
) => {
  const destinationList = [...(tasks[destinationListId] || [])]
  if (sourceListId === destinationListId) {
    if (destinationIndex < 0 || destinationIndex >= destinationList.length) {
      return null
    }

    destinationList.splice(sourceIndex, 1)
  } else {
    if (destinationIndex < 0 || destinationIndex > destinationList.length) {
      return null
    }
  }

  const a =
    destinationIndex === 0
      ? null
      : destinationList[destinationIndex - 1]?.taskSortingCode
  const b = destinationList[destinationIndex]
    ? destinationList[destinationIndex]?.taskSortingCode
    : null

  return generateKeyBetween(a, b)
}

export function taskShouldBePromoted(updates?: TaskType) {
  let shouldBeOnTodayList = false
  let shouldBePromoted = false
  let shouldBePlacedAtBottom = false

  if (
    !updates ||
    updates.priorityGroup === TaskPriorityGroup.RoutineDefinition ||
    updates.taskAssignees?.length
  ) {
    return { shouldBePromoted, shouldBeOnTodayList, shouldBePlacedAtBottom }
  }

  if (updates?.details?.flag === TaskPriority.URGENT) {
    shouldBePromoted = true
  } else if (updates.status === TaskStatusId.DONE) {
    shouldBePromoted = false
    shouldBeOnTodayList = true
    shouldBePlacedAtBottom = true
  }

  if (updates.dueAt && dayjs().diff(dayjs(updates.dueAt), 'd') === 0) {
    shouldBeOnTodayList = true
    shouldBePromoted = true
  }

  if (updates.scheduledTask?.startDate && !updates.scheduledTask?.initialDate) {
    // This is a scheduled task, not a routine
    if (dayjs(updates.scheduledTask?.startDate).isSame(dayjs(), 'day')) {
      shouldBePromoted = true
      shouldBeOnTodayList = true
    }
  }

  return { shouldBePromoted, shouldBeOnTodayList, shouldBePlacedAtBottom }
}

export const scheduledDateFormatted = (
  dateFormat: string,
  timeFormat: string,
  scheduledTask?: Partial<ScheduledTask> | null,
) => {
  if (!scheduledTask?.startDate) {
    return ''
  }
  const format = scheduledTask.allDay
    ? dateFormat
    : `${dateFormat} ${timeFormat}`
  return dayjs(scheduledTask.startDate).format(format)
}

export const isScheduled = (
  task: Partial<TaskItem> | Partial<TaskNodeData>,
) => {
  if (!task.scheduledTask) {
    return false
  }
  if (isRecurring(task)) {
    return false
  }
  return true
}

export const isScheduledForToday = (
  task: Partial<TaskItem> | Partial<TaskNodeData>,
) => {
  if (!task.scheduledTask) {
    return false
  }
  if (isRecurring(task)) {
    return false
  }
  const startDate = dayjs(task.scheduledTask.startDate)
  if (dayjs().isSame(startDate, 'day')) {
    return true
  }
  return false
}

export const isRecurring = (
  task: Partial<TaskItem> | Partial<TaskNodeData>,
) => {
  if (isRoutineDefinitionSchedule(task.scheduledTask)) {
    return true
  }
  if (isRoutineInstanceSchedule(task)) {
    return true
  }
  if (isInstanceOfSubtaskOfRoutine(task)) {
    return true
  }
  return false
}

export const isRoutineDefinitionSchedule = (
  scheduledTask?: Partial<ScheduledTask> | null,
) => {
  const recurRule = scheduledTask?.recurRule
  if (!recurRule) {
    return false
  }
  if (JSON.stringify(recurRule) === '{}') {
    return false
  }
  if (JSON.stringify(recurRule) === '[]') {
    return false
  }
  if (scheduledTask.initialDate) {
    return false
  }
  return true
}

type TaskItemOrTaskNodeScheduledType = {
  priorityGroup?: number | TaskCategory
  scheduledTask?: Partial<ScheduledTask> | null
}

export const isRoutineInstanceSchedule = (
  task: TaskItemOrTaskNodeScheduledType,
) => {
  let priorityGroup: number | undefined = undefined
  if (typeof task?.priorityGroup === 'number') {
    priorityGroup = task?.priorityGroup
  } else {
    priorityGroup = task?.priorityGroup?.id
  }
  return !!(
    task?.scheduledTask?.recurRule &&
    priorityGroup !== TaskPriorityGroup.RoutineDefinition
  )
}

export const isInstanceOfSubtaskOfRoutine = (
  task: Partial<TaskItem> | Partial<TaskNodeData>,
) => {
  if ('routineTask' in task) {
    if (!task?.routineTask) {
      return false
    }
  } else {
    return false
  }
  if (!task.parent?.id) {
    return false
  }
  if (task.scheduledTask) {
    return false
  }
  return true
}

export function sortTasksByASCIICode(array: TaskItem[]) {
  const sortedArray = [...array]
  sortedArray.sort((taskA, taskB) => {
    const a = taskA?.taskSortingCode || ''
    const b = taskB?.taskSortingCode || ''
    const minLength = Math.min(a.length, b.length)
    for (let i = 0; i < minLength; i++) {
      const aCode = a.charCodeAt(i)
      const bCode = b.charCodeAt(i)
      if (aCode < bCode) {
        return -1
      } else if (aCode > bCode) {
        return 1
      }
    }
    if (a.length < b.length) {
      return -1
    } else if (a.length > b.length) {
      return 1
    } else {
      return 0
    }
  })
  return sortedArray
}

export function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email)
}

export const extractTextFromJson = (node: DescriptionJsonNode): string => {
  let textString = ''
  if (node.children) {
    for (let child of node.children) {
      if (child.text) {
        textString += child.text + ' '
      }

      textString += extractTextFromJson(child)
    }
  }

  return textString
}

export const isJson = (str: string) => {
  try {
    JSON.parse(str)
    return true
  } catch (error) {
    return false
  }
}

export const getGroupNameWithOwnerIfNeeded = (
  task: Task | TaskItem | TaskNodeData,
  groups?: TaskGroup[],
) => {
  const localGroup = groups?.find((group) => group.id === task.group?.id)
  if (localGroup) {
    return localGroup.alternativeTitle || localGroup.title
  }
  const owner = task.group?.owner?.name
  const title = task.group?.title || ''
  return owner ? `${owner}'s ${title}` : `${title}`
}

export const getGroupsWithAlternativeNames = (
  groups: TaskGroup[],
): TaskGroup[] => {
  const titleCount = new Map()
  for (const { title } of groups) {
    const key = `${title}`
    titleCount.set(key, (titleCount.get(key) || 0) + 1)
  }
  return groups.map((group) => {
    const { title, groupMembers } = group
    const key = `${title}`
    if (titleCount.get(key) > 1) {
      const owner = groupMembers?.find(
        (gm) =>
          !!gm.rolesData.find((rd) => rd.id === PermissionRoleIDs.CREATOR),
      )?.name
      group.alternativeTitle = owner ? `${owner}'s ${title}` : title
    } else {
      group.alternativeTitle = title
    }
    return group
  })
}

export const getStartDateEndDateForAPICall = (
  day: Dayjs,
  viewType: OpUnitType,
) => {
  if (viewType === 'month') {
    const startVisibleDay = getPreviousSunday(day.startOf(viewType))
    const endVisibleDay = startVisibleDay.add(7 * 6, 'day')
    return {
      startDate: startVisibleDay.toISOString(),
      endDate: endVisibleDay.toISOString(),
    }
  }
  return {
    startDate: day.startOf(viewType).toISOString(),
    endDate: day.endOf(viewType).toISOString(),
  }
}

export const removeUncheckedCheckboxes = (htmlString: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')

  const checkboxes = doc.querySelectorAll(
    'li[role="checkbox"][aria-checked="false"]',
  )
  checkboxes.forEach((checkbox) => {
    checkbox.remove()
  })

  return doc.documentElement.outerHTML
}

export const getPreviousSunday = (date: Dayjs) => {
  const dayOfWeek = date.day()
  const daysBack = dayOfWeek || 7
  return dayjs(date).add(daysBack * -1, 'day')
}

// Given the title of a routine instance, check to see if instance is for a different day
export const isInstanceBeforeToday = (inputString: string): boolean => {
  const regex = /\((\d{2})\/(\d{2})\/(\d{4})\)$/
  const match = inputString.match(regex)
  if (match) {
    const [, month, day, year] = match.map(Number)
    const dateFromInput = new Date(year, month - 1, day)
    const today = new Date()
    const normalizedInputDate = new Date(dateFromInput.setHours(0, 0, 0, 0))
    const normalizedToday = new Date(today.setHours(0, 0, 0, 0))
    return normalizedInputDate.getTime() < normalizedToday.getTime()
  }
  return false
}

export const convertTaskStatusToCurriculumStatus = (
  taskStatusId: TaskStatusId,
): CurriculumStatusId | undefined => {
  switch (taskStatusId) {
    case TaskStatusId.NOT_STARTED:
      return CurriculumStatusId.NOT_STARTED
    case TaskStatusId.ACTIVE:
      return CurriculumStatusId.ACTIVE
    // Since PAUSED does not have a direct equivalent, it returns undefined.
    case TaskStatusId.PAUSED:
      return undefined
    case TaskStatusId.DONE:
      return CurriculumStatusId.COMPLETED
    default:
      return undefined
  }
}

// We cannot get the date from the updatedAt field or the createdAt field
// since the curriculum routine lesson can be created at a date different
// than it is assigned. The indicator of what day it is assigned to is the
// date in the title
export const getDateFromTitle = (title: string) => {
  const matches = title.match(/\(([^)]+)\)/)
  return matches ? matches[1] : ''
}

export const calculateCompletedCurriculumTaskPercentage = (
  tasks: CurriculumTaskItem[],
) => {
  const completedTasks = tasks.filter((task) => task.status === 'Completed')
  return Math.round((completedTasks.length / tasks.length) * 100)
}

export const getAudioTaskAttachments = (files: UploadedFile[]) => {
  if (files.length === 0) {
    return []
  }
  const audioExtensions = ['m4a', 'mp4']
  const audioFiles = []
  for (const file of files) {
    const fileExtension = file.filename.slice(
      ((file.filename.lastIndexOf('.') - 1) >>> 0) + 2,
    )
    const isAudio = audioExtensions.includes(fileExtension)
    if (isAudio) {
      audioFiles.push(file.filename)
    }
  }
  return audioFiles
}

export const getFileType = (fileName: string) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff']
  const audioExtensions = ['m4a', 'mp4', 'mp3', 'wav', 'wma', 'aif']
  const documentExtensions = [
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'pdf',
    'csv',
    'txt',
    '.fig',
    'psd',
    'ai',
    'eps',
    'svg',
  ]
  const videExtensions = ['avi', 'mov', 'wmv']

  let fileType: string = ''

  const extension = fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2)
  if (extension) {
    if (imageExtensions.includes(extension)) {
      fileType = 'image'
    } else if (audioExtensions.includes(extension)) {
      fileType = 'audio'
    } else if (documentExtensions.includes(extension)) {
      fileType = 'document'
    } else if (videExtensions.includes(extension)) {
      fileType = 'video'
    } else {
      fileType = 'other'
    }
  }

  return fileType
}

export const groupIconList = (groups?: TaskGroup[]) => {
  const iconList: { [id: string]: { icon: string; color: string } } = {}
  if (groups) {
    groups.forEach((group) => {
      if (group.metadata.icon && group.metadata.color) {
        iconList[group.id] = {
          icon: group.metadata.icon,
          color: group.metadata.color,
        }
      }
    })
  }
  return iconList
}

export const isFormDirty = (
  initial: TaskFormProps,
  current: TaskFormProps,
): boolean => {
  const initialScheduledTask = JSON.stringify(initial?.scheduledTask)
  const currentScheduledTask = JSON.stringify(current?.scheduledTask)
  const initialDescription = decode(initial?.description).replace(
    /<[^>]+>/g,
    '',
  )
  const currentDescription = decode(current?.description).replace(
    /<[^>]+>/g,
    '',
  )

  const initialValues = {
    ...initial,
    description: initialDescription,
    scheduledTask: initialScheduledTask,
  }
  const currentValues = {
    ...current,
    description: currentDescription,
    scheduledTask: currentScheduledTask,
  }

  // extract values and make sure their in the same order
  const sortedInitialValues = Object.keys(initialValues)
    .sort()
    .map((key) => initialValues[key as keyof typeof initialValues])

  const sortedCurrentValues = Object.keys(currentValues)
    .sort()
    .map((key) => currentValues[key as keyof typeof currentValues])
  return !sortedInitialValues.every(
    (value, index) => value === sortedCurrentValues[index],
  )
}

export const isGroupOwner = (
  group?: Pick<TaskGroup, 'groupMembers'>,
  userId?: string,
) => {
  if (!userId) {
    return false
  }
  if (!group?.groupMembers) {
    return false
  }
  return !!group.groupMembers.find(
    (groupMember) =>
      groupMember.user === userId &&
      groupMember.rolesData.find(
        (role) => role.id === PermissionRoleIDs.CREATOR,
      ),
  )
}

export const isFavoriteGroup = (
  isPrivateGroup?: boolean,
  currentGroupId?: number,
  userConfigFavoriteGroupId?: number,
): boolean => {
  if (!currentGroupId) {
    return false
  }
  if (!userConfigFavoriteGroupId) {
    return !!isPrivateGroup
  }
  return currentGroupId === userConfigFavoriteGroupId
}

export const getPrivateGroup = (groups?: TaskGroup[], userId?: string) => {
  if (!userId) {
    return undefined
  }
  if (!groups) {
    return undefined
  }
  const privateGroup = groups.find(
    (group) =>
      !!group.isDefault &&
      !!group.groupMembers?.find(
        (gm) =>
          gm.user === userId &&
          gm.rolesData?.[0]?.id === PermissionRoleIDs.CREATOR,
      ),
  )
  if (!privateGroup) {
    const privateByName = groups.find(
      (group) =>
        group.title === 'Private' &&
        !!group.groupMembers?.find(
          (gm) =>
            gm.user === userId &&
            gm.rolesData?.[0]?.id === PermissionRoleIDs.CREATOR,
        ),
    )
    return privateByName?.id
  }
  return privateGroup?.id
}

export const checkIfAssigneeHasEditorPermissions = (
  task?: Pick<TaskItem, 'taskAssignees' | 'pendingAssignees'>,
) => {
  if (task?.taskAssignees?.length) {
    return task.taskAssignees[0]?.role?.[0]?.id !== PermissionRoleIDs.STANDARD
  }
  return (
    task?.pendingAssignees?.[0]?.role?.[0]?.id !== PermissionRoleIDs.STANDARD
  )
}

export const getAssigneeInvitationStatus = (
  task?: Pick<TaskItem, 'taskAssignees'> | Pick<TaskNodeData, 'taskAssignees'>,
) => {
  return task?.taskAssignees?.length
    ? AssigneeInvitationStatus.ACCEPTED
    : AssigneeInvitationStatus.SENT
}

export function hasKey<O extends object>(
  obj: O,
  key: PropertyKey,
): key is keyof O {
  return key in obj
}

export const isTaskNotStarted = (task: TaskItem) => {
  return task.status ? task.status.id === TaskStatusId.NOT_STARTED : true
}

export const isTaskActive = (task: TaskItem) => {
  return task.status?.id === TaskStatusId.ACTIVE
}

export const isTaskPaused = (task: TaskItem) => {
  return task.status?.id === TaskStatusId.PAUSED
}

export const isTaskDone = (task: TaskItem) => {
  return task.status?.id === TaskStatusId.DONE
}

export const isTaskDueToday = (task: TaskItem) => {
  const dueDate = dayjs(task.dueAt)
  const now = dayjs()
  const days = Math.ceil(dueDate.diff(now, 'day', true))

  return task.status?.id !== TaskStatusId.DONE && days === 0
}

export const isTaskOverdue = (task: TaskItem) => {
  const dueDate = dayjs(task.dueAt)
  const now = dayjs()
  const days = Math.ceil(dueDate.diff(now, 'day', true))

  return task.status?.id !== TaskStatusId.DONE && days < 0
}
