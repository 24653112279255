import { Collapse, CollapseProps, Input } from 'antd'
import { SearchProps } from 'antd/es/input'
import NoTaskPlaceholder from 'components/NoTaskPlaceholder'
import { useEffect, useState } from 'react'
import { TaskLists } from 'services/Tasks.slice'
import { TaskItem } from 'types/Tasks'
import CustomPanelHeader from '../CustomPanelHeader'
import DelegatePanel from './DelegatePanel'
import DelegatedTaskHeaderSummary from './DelegatedTaskHeaderSummary'
import DelegatedTaskHeatmap from './DelegatedTaskHeatmap'
import styles from './DelegatedTaskList.module.scss'

const { Search } = Input

function divideTasksByUser(tasks: TaskItem[], filterString: string): TaskLists {
  const validateDuplicatedTasks: { [key: string]: boolean } = {}
  const taskList: TaskLists = {}
  const filteredList = filterString
    ? tasks.filter(
        (task) =>
          task.title.toLowerCase().includes(filterString.toLowerCase()) ||
          task.taskAssignees?.[0]?.email
            ?.toLowerCase()
            ?.includes(filterString.toLowerCase()) ||
          task.pendingAssignees?.[0]?.email
            ?.toLowerCase()
            ?.includes(filterString.toLowerCase()),
      )
    : tasks
  filteredList.forEach((task) => {
    const userId =
      task.taskAssignees?.[0]?.email?.toLowerCase() ||
      task.pendingAssignees?.[0]?.email?.toLocaleLowerCase()
    if (userId) {
      if (!taskList[userId]) {
        taskList[userId] = []
      }
      if (!validateDuplicatedTasks[task.id]) {
        taskList[userId].push(task as TaskItem)
        validateDuplicatedTasks[task.id] = true
      }
    }
  })
  return taskList
}

type DelegatedTaskListProps = {
  tasks?: TaskItem[]
  onDismiss?: (task: TaskItem) => void
  pending?: boolean
}

const DelegatedTaskList = ({
  tasks,
  onDismiss,
  pending = false,
}: DelegatedTaskListProps) => {
  const [taskList, setTaskList] = useState<TaskLists>({})
  const [searchValue, setSearchValue] = useState('')
  const [activeKeys, setActiveKeys] = useState<string[]>([])

  const onChange = (key: string | string[]) => {
    const newKeys = Array.isArray(key) ? key : [key]
    setActiveKeys(newKeys)
  }

  const onSearch: SearchProps['onSearch'] = (value, _, info) => {
    setSearchValue(value)
  }

  useEffect(() => {
    setTaskList(divideTasksByUser(tasks || [], searchValue))
  }, [searchValue, tasks])

  const getItems = (
    tasks: TaskLists,
    activeKeys: string[],
    isExpanded: boolean,
  ) => {
    const items: CollapseProps['items'] = []
    if (!tasks) {
      return items
    }
    Object.keys(tasks).forEach((key) => {
      const title = pending
        ? tasks[key]?.[0]?.user.name
        : tasks[key]?.[0]?.taskAssignees?.[0]?.email ||
          tasks[key]?.[0]?.pendingAssignees?.[0]?.email
      const count = tasks[key]?.length || 0
      items.push({
        key: key,
        label: pending ? (
          <CustomPanelHeader
            title={`${title} (${count})`}
            isActive={activeKeys.includes(key)}
          />
        ) : (
          <CustomPanelHeader
            title={`${title} `}
            isActive={activeKeys.includes(key)}
            prefix={<DelegatedTaskHeaderSummary tasks={tasks[key]} />}
            suffix={<DelegatedTaskHeatmap tasks={tasks[key]} />}
          />
        ),
        showArrow: false,
        className: styles.customPanel,
        children: (
          <DelegatePanel
            tasks={tasks[key]}
            isExpanded={isExpanded}
            isReadonly={true}
            onDismiss={onDismiss}
          />
        ),
      })
    })
    return items
  }

  return (
    <div className={styles.tasksListWrapper}>
      <Search
        placeholder="Search"
        style={{ width: '100%', marginBottom: '8px' }}
        onSearch={onSearch}
        enterButton
        allowClear
      />
      {Object.keys(taskList).length ? (
        <Collapse
          activeKey={activeKeys}
          onChange={onChange}
          className={styles.taskPanel}
          items={getItems(taskList, activeKeys, false)}
        />
      ) : (
        <NoTaskPlaceholder />
      )}
    </div>
  )
}

export default DelegatedTaskList
